const validation = {
  password: 'Wachtwoord invoeren',
  new_password: 'Voer een nieuw wachtwoord in',
  repeat_password: 'Bevestig uw wachtwoord',
  email: 'E-mailadres is noodzakelijk',
  email_format: 'Moet een geldig e-mailadres zijn',
  token: 'Geen geldige token verstrekt',
  message: 'Bericht is vereist',
  first_name: 'Voer uw voornaam in',
  last_name: 'Voer uw achternaam in',
  phone: 'Voer uw telefoonnummer in',
  vat: 'BTW is vereist',
  company_name: 'Voer de naam van uw bedrijf in',
  agree: 'Geef akkoord om verder te gaan',
  time: 'Tijdslot is verplicht'
}

export default {
  locales: {
    nl: 'NL',
    en: 'EN',
    de: 'DE',
  },
  navigation: {
    spaces: "Locaties",
    contact: "Contact",
    news: "Nieuws",
    about: "Over ons",
    support: "Veelgestelde vragen",
    login: "Inloggen operators",
    admin: "Admin",
    menu: "Menu",
    amsterdam: "Amsterdam",
    rotterdam: "Rotterdam",
    utrecht: "Utrecht",
    the_hague: "Den Haag",
    frankfurt: 'Frankfurt',
    logout: "Uitloggen",
    popular: "Vaak geboekte locaties",
    discover: "Ontdekken",
    usp1: 'Boek voor vandaag (of vanaf 15 uur voor morgen)',
    usp2: 'In een paar klikken te boeken',
    usp3: 'We zitten op alle A-locaties door het hele land',
    usp4: 'Één tarief voor zowel kantoor- als vergaderruimte',
  },
  availability: {
    full_day: "Kantoorruimte (tijdens openingstijden)",
    morning: "Vergaderruimte (max. 3 personen) ochtend (tot 13:00 uur)",
    morning_double: "Vergaderruimte (4-6 personen) ochtend (tot 13:00 uur)",
    evening: "Vergaderruimte (max. 3 personen) middag (na 13:00 uur)",
    evening_double: "Vergaderruimte (4-6 personen) middag (na 13:00 uur)",
  },
  home: {
    faq: {
      content: "Heb je je vraag niet gevonden? Bezoek onze {help} voor meer antwoorden.",
      help: "FAQ pagina",
      contact: "Contactpagina"
    }
  },
  footer: {
    content: "Hét platform voor het boeken van betaalbare en bereikbare\nkantoorruimte-voor-een-dag of vergaderruimte voor iedereen!",
    redwood: "{worksnap} is onderdeel van {link}",
    privacy: "Privacybeleid",
    terms: "Voorwaarden"
  },
  spaces: {
    workspaces_found: "{n} locatie gevonden | {n} locaties gevonden",
    map: "Zoeken op de kaart",
    list: "Terug naar zoekresultaten"
  },
  space: {
    about: "Over deze locatie",
    time_placeholder: "Selecteer het type ruimte",
    costs: "Totale kosten",
    subtotal: "Subtotaal",
    vat: "BTW ({amount}%)",
    coupon: "Coupon",
    total: "Totaal",
    excl_vat: "(Excl. BTW)",
    book: "Nu boeken",
    additional_information: "Extra informatie",
    note: "De prijs is inclusief de reservering van de geselecteerde ruimte voor minimaal 4 uur per dag, op basis van de ochtend-/middagselectie.",
    pay: "Ga verder naar afrekenen",
    active_state: {
      title: "Weet je het zeker?",
      close: "Sluit voor vandaag",
      close_office: "Kantoorruimte voor vandaag sluiten",
      close_meeting: "Vergaderruimte voor vandaag sluiten",
      close_message: "Als deze locatie te veel boekingen heeft, moeten ze handmatig worden geannuleerd.",
      close_loading: "Bezig met sluiten...",
      open: "Open voor de dag",
      open_office: "Kantoorruimte voor vandaag openen",
      open_meeting: "Vergaderruimte voor vandaag openen",
      open_message: "",
      open_loading: "Bezig met openen..."
    }
  },
  voucher: {
    placeholder: "Voucher- of couponcode",
    remove: "Verwijderen",
    apply: "Toepassen"
  },
  search_bar: {
    address_placeholder: "Voer een locatie in",
    date_placeholder: "Selecteer een datum",
    location_placeholder: "Type ruimte",
    amentities_placeholder: "Diensten",
    search: "Zoeken"
  },
  dates: {
    today: "Vandaag",
    tomorrow: "Morgen",
    yesterday: "Gisteren",
    at: "om"
  },
  loading: "Laden...",
  checkout: {
    billing: {
      title: "Factuurgegevens",
      first_name_placeholder: "Voornaam*",
      last_name_placeholder: "Achternaam*",
      email_placeholder: "E-mailadres*",
      phone_placeholder: "Telefoonnummer*",
      vat_placeholder: "BTW-nummer",
      company_name_placeholder: "Bedrijfsnaam (optioneel)",
      password: "Wachtwoord"
    },
    business: "Zakelijke boeking",
    agree: {
      content: "Ik ga akkoord met de {terms} van de aanbieder van deze locatie*",
      terms: "algemene voorwaarden"
    },
    save: "Sla mijn gegevens op voor toekomstige boekingen",
    payment: {
      title: "Je betalingsgegevens",
      bank_placeholder: "Kies je bank",
      issuer_placeholder: "Selecteer je betaalmethode",
      note: "Je persoonlijke informatie wordt gebruikt om je bestelling te verwerken, je ervaring op deze website te ondersteunen en voor andere doeleinden zoals beschreven in ons privacybeleid.",
      total: "Totaal"
    },
    note: "De prijs is inclusief de reservering van de geselecteerde ruimte voor minimaal 4 uur per dag, op basis van de ochtend-/middagselectie.",
    confirm: "Bevestigen en betalen",
    reservation: {
      title: "Je boeking",
      about: "Over deze locatie"
    }
  },
  checkout_response: {
    title: "Je betaling is succesvol verwerkt!",
    message: "Je ontvangt binnenkort een bevestigingsmail.",
    close: "Sluiten"
  },
  contact: {
    name_placeholder: "Je naam",
    email_placeholder: "Je e-mail",
    message_placeholder: "Je bericht",
    send: "Verzenden",
    sending: "Bezig met verzenden..."
  },
  login: {
    title: "Inloggen",
    email: "E-mailadres",
    password: "Wachtwoord",
    login: "Inloggen",
    loading: "Laden...",
    forgot: "Wachtwoord vergeten"
  },
  reset: {
    title: "Wachtwoord vergeten",
    email: "E-mailadres",
    reset: "Wachtwoord opnieuw instellen",
    loading: "Laden...",
    login: "Inloggen"
  },
  forgot: {
    title: "Wachtwoord opnieuw instellen",
    password: "Wachtwoord",
    repeat_password: "Bevestig wachtwoord",
    reset: "Wachtwoord opnieuw instellen",
    loading: "Laden...",
    login: "Inloggen",
    email: "E-mailadres"
  },
  location_table: {
    name: 'Naam locatie',
    address: 'Adres',
    office_open: 'Kantoorruimte vandaag open?',
    meeting_open: 'Vergaderruimte vandaag open?',
    view: 'Bekijken',
  },
  booking_table: {
    location: "Lokatie",
    address: "Adres",
    start_date: "Startdatum",
    end_date: "Einddatum",
    guest_name: "Naam gast",
    guest_email: "E-mailadres gast",
    guest_phone: "Telefoonnummer",
    view: "Bekijken"
  },
  pagination: {
    next: "Volgende",
    previous: "Vorige",
    page: "Pagina"
  },
  validation,
  auth: {
    validation,
  },
}
